import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Define supported languages
const SUPPORTED_LANGUAGES = ['ptbr', 'en', 'es'];
const DEFAULT_LANGUAGE = 'ptbr';
const FALLBACK_LANGUAGE = 'en';

// Initialize with basic structure for all supported languages
const initialResources = SUPPORTED_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = { translation: {} };
  return acc;
}, {});

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: initialResources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    load: 'languageOnly',
    debug: process.env.NODE_ENV === 'dev',
    keySeparator: false,
    nsSeparator: false,
    returnEmptyString: false,
    returnNull: false,
    // Add missing languages support
    supportedLngs: SUPPORTED_LANGUAGES,
    preload: SUPPORTED_LANGUAGES
  });

  const fetchWithRetry = async (url, retries = 3, timeout = 5000) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
  
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url, { signal: controller.signal });
        clearTimeout(timeoutId);
        return response;
      } catch (error) {
        if (i === retries - 1) throw error;
        await new Promise(r => setTimeout(r, 1000 * Math.pow(2, i)));
      }
    }
  };

  const fetchTranslationsFromAPI = async (language) => {
    try {
      const response = await fetchWithRetry(`https://x9bvb7w9sj.execute-api.us-east-1.amazonaws.com/prod/translations/${language}`);
      
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error(`Error fetching translations for ${language}:`, error);
      throw error;
    }
  };
  
export const initializeI18n = async () => {
  const loadingPromises = [];
  const loadedLanguages = new Set();

  try {
    // Load all supported languages in parallel
    for (const language of SUPPORTED_LANGUAGES) {
      const loadLanguage = async () => {
        try {
          const translations = await fetchTranslationsFromAPI(language);
          if (translations) {
            i18n.addResourceBundle(language, 'translation', translations, true, true);
            loadedLanguages.add(language);
          } else {
            console.warn(`No translations found for ${language}`);
          }
        } catch (error) {
          console.error(`Failed to load ${language} translations:`, error);
        }
      };
      loadingPromises.push(loadLanguage());
    }

    // Wait for all languages to load
    await Promise.all(loadingPromises);

    // Verify that at least the default and fallback languages are loaded
    if (!loadedLanguages.has(DEFAULT_LANGUAGE)) {
      console.error(`Failed to load default language (${DEFAULT_LANGUAGE})`);
    }
    if (!loadedLanguages.has(FALLBACK_LANGUAGE)) {
      console.error(`Failed to load fallback language (${FALLBACK_LANGUAGE})`);
    }

    return i18n;
  } catch (error) {
    console.error('Failed to initialize i18n:', error);
    throw error;
  }
};

const translationCache = new Map();

export const changeLanguage = async (language) => {
  if (!SUPPORTED_LANGUAGES.includes(language)) throw new Error(`Unsupported language: ${language}`);

  try {
    if (!i18n.hasResourceBundle(language, 'translation')) {
      if (translationCache.has(language)) {
        i18n.addResourceBundle(language, 'translation', translationCache.get(language), true);
      } else {
        const translations = await fetchTranslationsFromAPI(language);
        if (translations) {
          translationCache.set(language, translations);
          i18n.addResourceBundle(language, 'translation', translations, true);
        }
      }
    }

    await i18n.changeLanguage(language);
    return true;
  } catch (error) {
    console.error(`Error changing language to ${language}:`, error);
    throw error;
  }
};

export default i18n;